<template>
  <en-dialog :model-value="modelValue" title="单据历史" center @close="$emit('update:model-value', false)" width="50%">
    <en-collapse>
      <en-collapse-item title="单据基础信息">
        <div>
          <span class="p-2">单据编号 :{{ data?.serialNo }}</span>
          <span class="p-2" v-if="code === 'SAL' || code === 'SALR'">客户单位 :{{ data?.client?.name }}</span>
          <span class="p-2" v-if="code === 'OTH'">供应商 :{{ data?.auditBy?.name }}</span>
          <span class="p-2" v-if="code !== 'TRA'">贷款金额 :{{ formatMoney(data?.totalAmount) }}</span>
          <span class="p-2" v-if="code !== 'TRA'">物流费用 :{{ formatMoney(data?.freight) }}</span>
          <span class="p-2" v-if="code !== 'TRA'">其他费用 :{{ formatMoney(data?.otherCharge) }}</span>
          <span class="p-2" v-if="code !== 'TRA'">应付总额 :{{ formatMoney(data?.totalAmount+data?.freight+data?.otherCharge) }}</span>
        </div>
      </en-collapse-item>
      <en-collapse-item title="单据审核信息">
        <span>备注:{{ data?.comment }}</span>
        <div>单据历史：
    <div v-for="item in data?.logs" class="p-1">
    <span class="pl-15 ">{{ item.operator.name }}</span>
    <span class="p-3 ">{{ item. message}}</span>
    <span class="p-2 ">{{ formatDate(item.datetime )}}</span>
  </div>
  </div>
      </en-collapse-item>
    </en-collapse>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudInventoryDefinitions['SaleDto' | 'StockTransferOutDto']>,
    code: {
      type: String as PropType<'SAL' | 'SALR' | 'OTH' | 'TRA'>,
      default: ''
    }
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  }
})
</script>
